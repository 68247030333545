import React from "react";
import BgForm from "../../images/bg-form.png";
import IconFlight from "../../images/ic-flights.svg";
import IconFlightTrip from "../../images/ic_flighttrip.svg";
import { calculateTimeDifference } from "../../helpers/utils";
import { Button, Tag } from "antd";
import moment from "moment";
import _ from "lodash";

const Step4 = ({
  onChangeStep,
  bookingInfo,
  chooseFlight,
  airportInfo,
  isPromotionOrSameDayTicket,
  airlineInfo,
}) => {
  return (
    <div className="bg-[#F6F6F6] mb-4">
      <div
        className="h-[300px] relative"
        style={{
          background: "linear-gradient(to right, #36C643, #267EA4)",
        }}
      >
        <section className="step-indicator">
          <div className="step step1 actived">
            <div className="step-icon" onClick={() => onChangeStep(1)}>
              1
            </div>
            <p>Tìm kiếm</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step2 actived">
            <div className="step-icon">2</div>
            <p>Thông tin</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step3 actived">
            <div className="step-icon">3</div>
            <p>Xác nhận</p>
          </div>
          <div className="indicator-line active"></div>
          <div className="step step4 active">
            <div className="step-icon">4</div>
            <p>Thanh Toán</p>
          </div>
        </section>
        <img src={BgForm} alt="" className="w-full object-cover mx-auto" />
        <div className="flex w-full absolute top-[135px] left-1/2 transform -translate-x-1/2 justify-center gap-8">
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight[0].ListFlight[0].StartPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].StartPoint
                )?.city_name_vi
              }
            </div>
          </div>
          <div className="relative">
            <img src={IconFlight} alt="" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-white font-semibold text-[18px]">
              {chooseFlight[0].ListFlight[0]?.EndPoint}
            </div>
            <div className="text-white font-medium text-[11px]">
              {
                airportInfo.find(
                  (air) => air.code === chooseFlight[0].ListFlight[0].EndPoint
                )?.city_name_vi
              }
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto absolute top-[200px] left-1/2 transform -translate-x-1/2 pb-[120px] flex flex-col">
          <div
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            className="rounded-[20px] bg-white p-4 mb-[24px] font-medium text-[14px] text-[#0D1634]"
          >
            <div className="text-[20px] mb-3">Thông tin đơn hàng</div>
            <div className="flex items-center mb-2">
              <span>Mã đơn hàng: </span>
              <span className="text-[20px] text-[#01AD53] pl-2">
                {isPromotionOrSameDayTicket
                  ? bookingInfo.orderId
                  : bookingInfo.transaction.orderCode}
              </span>
            </div>
            {!isPromotionOrSameDayTicket && (
              <div className="text-[#D72F20] italic font-bold">
                Đơn hàng hết hạn ngày{" "}
                {moment(
                  bookingInfo.transaction.vmbBookings[0].expiryDate
                ).format("DD/MM/YYYY HH:mm")}
              </div>
            )}
            <hr className="my-3" style={{ borderTopWidth: "2px" }} />
            <div>Hệ thống đã đặt vé thành công.</div>
            <div className=" mb-3">
              Cảm ơn Quý khách đã sử dụng dịch vụ, chúng tôi sẽ liên lạc lại với
              Quý khách hàng sớm nhất.
            </div>
            <div>Mọi thắc mắc xin liên hệ:</div>
            <div>
              <a
                href="tel:0876100800"
                className="font-semibold"
                style={{
                  textDecoration: "none",
                }}
              >
                Hotline: 0876.100.800
              </a>
            </div>
            <div className="mt-3">
              Trụ sở: Số 10 ngõ 83 phố Bảo Linh, Phường Phúc Tân, Quận Hoàn Kiếm, Thành phố Hà Nội, Việt Nam
            </div>
            <div className="my-3">
              Văn phòng Hà Nội: Toà nhà Việt Á, Số 9 Duy Tân, Dịch Vọng Hậu, Cầu Giấy, Hà Nội
            </div>
            <div className="my-3">
              Văn phòng Hồ Chí Minh: Số 101 Tân Hóa, Phường 14, Quận 6, Thành phố Hồ Chí Minh
          </div>
            <div className="text-[20px] mb-3">Thông tin liên hệ</div>
            <ul className="list-none py-4">
              <li className="flex justify-between mb-2">
                <span className="text-[#0B2727]">Name</span>
                <span>
                  {bookingInfo.ContactFirstName + bookingInfo.ContactLastName}
                </span>
              </li>
              <li className="flex justify-between mb-2">
                <span className="text-[#0B2727]">Email</span>
                <span>{bookingInfo.ContactEmail}</span>
              </li>
              <li className="flex justify-between">
                <span className="text-[#0B2727]">Phone</span>
                <span>{bookingInfo.ContactPhone}</span>
              </li>
            </ul>
            <div className="text-[20px] my-3">Trạng thái thanh toán</div>
            <div className="flex justify-between items-center">
              <span className="text-[#0B2727]">Trạng thái</span>
              <Tag
                color="#FFBB29"
                className="text-[14px] text-black rounded-[20px] px-2 py-1 mr-0"
              >
                Chưa thanh toán
              </Tag>
            </div>
            <div className="text-[20px] my-3">Thông tin chuyến bay</div>
            <ul className="list-none py-4">
              <li className="flex justify-between mb-2">
                <span className="text-[#0B2727]">Loại hành trình</span>
                <span>
                  {chooseFlight[0].FlightType === "domestic"
                    ? "Nội địa"
                    : "Quốc tế"}{" "}
                  - {chooseFlight.length === 1 ? "1 chiều" : "2 chiều"}
                </span>
              </li>
              <li className="flex justify-between mb-2">
                <span className="text-[#0B2727]">Ngày đi</span>
                <span>
                  {moment(chooseFlight[0].ListFlight[0].StartDate).format(
                    "DD-MM-YYYY"
                  )}
                </span>
              </li>
              {chooseFlight.length === 2 && (
                <li className="flex justify-between mb-2">
                  <span className="text-[#0B2727]">Ngày về</span>
                  <span>
                    {moment(chooseFlight[1].ListFlight[0].StartDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </li>
              )}

              <li className="flex justify-between">
                <span className="text-[#0B2727]">Trạng thái</span>
                <Tag
                  color="#01ad53"
                  className="text-[14px] rounded-[20px] px-2 py-1 mr-0"
                >
                  Đã giữ chỗ
                </Tag>
              </li>
            </ul>
            <hr className="mt-2 mb-4" style={{ borderTopWidth: "2px" }} />
            <div className="mb-3">
              <div className="flex w-full justify-between items-center mb-4">
                <div
                  className="flex flex-col gap-1 items-start"
                  style={{ width: "90px" }}
                >
                  <div className="text-[#22313F] font-semibold text-[20px]">
                    {chooseFlight[0].ListFlight[0].StartPoint}
                  </div>
                  <div className="text-[#0B2727] font-medium text-[11px]">
                    {
                      airportInfo.find(
                        (air) =>
                          air.code === chooseFlight[0].ListFlight[0].StartPoint
                      )?.city_name_vi
                    }
                  </div>
                </div>
                <div className="relative flex-1 text-center">
                  <img src={IconFlightTrip} alt="" />
                  <div className="text-[12px] text-[#22313F] absolute left-1/2 transform -translate-x-1/2">
                    {" "}
                    {calculateTimeDifference(
                      chooseFlight[0].ListFlight[0].StartDate,
                      chooseFlight[0].ListFlight[0].EndDate
                    )}
                  </div>
                </div>
                <div
                  className="flex flex-col gap-1 items-end"
                  style={{ width: "90px" }}
                >
                  <div className="text-[#22313F] font-semibold text-[20px] text-right">
                    {chooseFlight[0].ListFlight[0].EndPoint}
                  </div>
                  <div className="text-[#0B2727] font-medium text-[11px]">
                    {
                      airportInfo.find(
                        (air) =>
                          air.code === chooseFlight[0].ListFlight[0].EndPoint
                      )?.city_name_vi
                    }
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-between items-center mb-2">
                <div
                  className="flex flex-col gap-1 items-start"
                  style={{ width: "90px" }}
                >
                  <div className="text-[#22313F] font-semibold text-[16px]">
                    {moment(chooseFlight[0].ListFlight[0].StartDate).format(
                      "HH:mm"
                    )}
                  </div>
                  <div className="text-[#0B2727] font-medium text-[11px]">
                    {moment(chooseFlight[0].ListFlight[0].StartDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </div>
                <div
                  className="flex flex-col gap-1 items-end"
                  style={{ width: "90px" }}
                >
                  <div className="text-[#22313F] font-semibold text-[16px]">
                    {moment(chooseFlight[0].ListFlight[0].EndDate).format(
                      "HH:mm"
                    )}
                  </div>
                  <div className="text-[#0B2727] font-medium text-[11px]">
                    {moment(chooseFlight[0].ListFlight[0].EndDate).format(
                      "DD-MM-YYYY"
                    )}
                  </div>
                </div>
              </div>
              <img
                src={`https://abtrip.vn/images/airlines/${
                  airlineInfo.find(
                    (air) => air.code === chooseFlight[0].Airline
                  ).logo
                }`}
                alt=""
                className="w-[60px] h-[30px]"
              />
            </div>
            {chooseFlight.length === 2 && (
              <>
                <hr
                  className="my-4"
                  style={{ borderTopWidth: "1px", borderStyle: "dashed" }}
                />
                <div className="mb-3">
                  <div className="flex w-full justify-between items-center mb-4">
                    <div
                      className="flex flex-col gap-1 items-start"
                      style={{ width: "90px" }}
                    >
                      <div className="text-[#22313F] font-semibold text-[20px]">
                        {chooseFlight[1].ListFlight[0].StartPoint}
                      </div>
                      <div className="text-[#0B2727] font-medium text-[11px]">
                        {
                          airportInfo.find(
                            (air) =>
                              air.code ===
                              chooseFlight[1].ListFlight[0].StartPoint
                          )?.city_name_vi
                        }
                      </div>
                    </div>
                    <div className="relative flex-1 text-center">
                      <img src={IconFlightTrip} alt="" />
                      <div className="text-[12px] text-[#22313F] absolute left-1/2 transform -translate-x-1/2">
                        {" "}
                        {calculateTimeDifference(
                          chooseFlight[1].ListFlight[0].StartDate,
                          chooseFlight[1].ListFlight[0].EndDate
                        )}
                      </div>
                    </div>
                    <div
                      className="flex flex-col gap-1 items-end"
                      style={{ width: "90px" }}
                    >
                      <div className="text-[#22313F] font-semibold text-[20px] text-right">
                        {chooseFlight[1].ListFlight[0].EndPoint}
                      </div>
                      <div className="text-[#0B2727] font-medium text-[11px]">
                        {
                          airportInfo.find(
                            (air) =>
                              air.code ===
                              chooseFlight[1].ListFlight[0].EndPoint
                          )?.city_name_vi
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full justify-between items-center mb-2">
                    <div
                      className="flex flex-col gap-1 items-start"
                      style={{ width: "90px" }}
                    >
                      <div className="text-[#22313F] font-semibold text-[16px]">
                        {moment(chooseFlight[1].ListFlight[0].StartDate).format(
                          "HH:mm"
                        )}
                      </div>
                      <div className="text-[#0B2727] font-medium text-[11px]">
                        {moment(chooseFlight[1].ListFlight[0].StartDate).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                    <div
                      className="flex flex-col gap-1 items-end"
                      style={{ width: "90px" }}
                    >
                      <div className="text-[#22313F] font-semibold text-[16px]">
                        {moment(chooseFlight[1].ListFlight[0].EndDate).format(
                          "HH:mm"
                        )}
                      </div>
                      <div className="text-[#0B2727] font-medium text-[11px]">
                        {moment(chooseFlight[1].ListFlight[0].EndDate).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <img
                    src={`https://abtrip.vn/images/airlines/${
                      airlineInfo.find(
                        (air) => air.code === chooseFlight[1].Airline
                      ).logo
                    }`}
                    alt=""
                    className="w-[60px] h-[30px]"
                  />
                </div>
              </>
            )}
            <hr
              className="my-4"
              style={{ borderTopWidth: "1px", borderStyle: "dashed" }}
            />
            <div className="flex w-full justify-between items-baseline">
              <div className="text-[#0B2727] mb-3">Tổng số tiền</div>
              <Tag
                color="#01AD53"
                className="text-[16px] font-semibold rounded-[20px] px-2 py-1 mr-0"
              >
                {bookingInfo.totalListPrice.totalDisplay.toLocaleString(
                  "vi-VN"
                )}{" "}
                ₫
              </Tag>
            </div>
          </div>
          <Button
            onClick={() => onChangeStep(5)}
            variant="filled"
            color="#01AD53"
            className="w-[208px] h-[45px] rounded-[10px] text-center mx-auto bg-[#01AD53] text-white text-[15px]"
          >
            <span className="font-semibold">Tiếp tục</span>
          </Button>
        </div>
        {/* <div className="flex bg-white h-[81px] w-full px-[24px] py-3 fixed bottom-0 justify-between items-center">
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
            <a href="/ve-may-bay" className="text-[#22313F] text-[14px] font-medium">
              Vé máy bay
            </a>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Khách sạn</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé tàu</span>
          </div>
          <div className="flex flex-col items-center">
            <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
            <span className="text-[#808080CC] text-[14px] font-medium">Vé xe</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Step4;
